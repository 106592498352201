/* import __COLOCATED_TEMPLATE__ from './fin-identity-editor.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { ref } from 'ember-ref-bucket';
import { defer } from 'rsvp';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';

export interface FinIdentityEditorArgs {
  identity: $TSFixMe; // app/models/operator-identity
  reloadPreviewTracker: boolean;
}

export default class FinIdentityEditor extends Component<FinIdentityEditorArgs> {
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare intercomConfirmService: $TSFixMe;
  @service declare store: Store;

  @ref('inputElement') inputElement!: HTMLInputElement;
  @tracked fileWasChosen = false;
  @tracked customAvatar = false;
  @tracked identityPreview = this.args.identity;

  constructor(owner: unknown, args: FinIdentityEditorArgs) {
    super(owner, args);
  }

  get isLoading() {
    return !this.args.identity;
  }

  get savedAvatarIsCustom() {
    return this.args.identity.avatarType === 'custom';
  }

  @action
  async restoreIdentityToDefault() {
    if (!this.savedAvatarIsCustom) {
      return;
    }
    let confirmOptions = {
      title: this.intl.t('operator.settings.restore-defaults.bot-identity'),
      confirmButtonText: this.intl.t('operator.settings.restore-defaults.restore-default'),
      cancelButtonText: this.intl.t('operator.settings.restore-defaults.cancel'),
      body: this.intl.t('operator.settings.restore-defaults.body-fin'),
    };

    let isConfirmed = await this.intercomConfirmService.confirm(confirmOptions);
    if (!isConfirmed) {
      return;
    }

    await this.args.identity
      .revert()
      .catch(() => this.notificationsService.notifyError(this.intl.t('operator.settings.error')));

    this.reloadPreview();

    this.notificationsService.notifyConfirmation(
      this.intl.t('operator.settings.restore-defaults.restored-identity'),
    );
  }

  @action
  openFilePicker() {
    this.inputElement.click();
  }

  @action
  async setCustomAvatarFromFilePicker(fileList: FileList) {
    if (fileList.length < 1) {
      this.fileWasChosen = false;
      return;
    } else {
      this.fileWasChosen = true;
      let dataUrl = await this._readFileAsDataURL(fileList[0]);
      this.args.identity.avatarType = 'custom';
      this.args.identity.set('avatarUpload', fileList[0]);
      this.args.identity.set('avatarData', {
        avatarShape: this.args.identity.avatarData.avatarShape,
        url: dataUrl,
      });

      this._clearFilePicker();
    }
  }

  @action
  reloadPreview() {
    this.identityPreview.avatarType = this.args.identity.avatarType;
    this.identityPreview.set('avatarUpload', this.args.identity.avatarUpload);
    this.identityPreview.set('avatarData', {
      avatarShape: this.args.identity.avatarData.avatarShape,
      url: this.args.identity.avatar.image_urls.square_128,
    });
  }

  _readFileAsDataURL(file: File): Promise<unknown> {
    let deferred = defer();
    let reader = new FileReader();
    reader.onload = (e) => {
      if (e.target && typeof e.target.result === 'string') {
        deferred.resolve(e.target.result);
      }
    };
    reader.readAsDataURL(file);
    return deferred.promise;
  }

  _clearFilePicker() {
    if (this.inputElement) {
      this.inputElement.value = '';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::FinIdentityEditor': typeof FinIdentityEditor;
  }
}
