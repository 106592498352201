/* import __COLOCATED_TEMPLATE__ from './configure-workflows-quick-deploy.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';
import { type ChannelType } from 'embercom/lib/workflows/fin-workflow-preview';

export interface ConfigureWorkflowsQuickDeployArgs {
  previewConfiguration: $TSFixMe;
  hasContentReadyForFin: boolean;
  finSetupRuleset: $TSFixMe;
  reloadSetupRuleset: (setupType: ChannelType) => void;
  channelType: ChannelType;
  totalLiveFinProfilesCount: number;
}

const ConfigureWorkflowsQuickDeploy = templateOnlyComponent<ConfigureWorkflowsQuickDeployArgs>();
export default ConfigureWorkflowsQuickDeploy;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::ConfigureWorkflowsQuickDeploy': typeof ConfigureWorkflowsQuickDeploy;
  }
}
