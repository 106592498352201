/* import __COLOCATED_TEMPLATE__ from './configure-starter-plan.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import { type FinSetupBehaviorSaveParams } from 'embercom/lib/operator/fin/types';
import { put } from 'embercom/lib/ajax';
import type Profile from 'embercom/models/ai-agent/profile';
import type GuideLibraryService from 'embercom/services/guide-library-service';
import type { ChannelType } from 'embercom/lib/workflows/fin-workflow-preview';
import type { BannerContent } from 'embercom/components/operator/fin/setup/fin-content-banner';
import type AiAgentSetupService from 'embercom/services/ai-agent-setup-service';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type FinOptInService from 'embercom/services/fin-opt-in-service';
import { taskFor } from 'ember-concurrency-ts';
import { CONVERSATION_STARTED_TARGET } from 'embercom/lib/operator/custom-bots/constants';
import { type TaskGenerator } from 'ember-concurrency';
import { objectTypes, states } from 'embercom/models/data/matching-system/matching-constants';
import type RouterService from '@ember/routing/router-service';

interface Args {
  previewConfiguration: $TSFixMe;
  hasContentReadyForFin: boolean;
  finSetupRuleset: $TSFixMe;
  reloadSetupRuleset: (setupType: ChannelType) => void;
  channelType: ChannelType;
  totalLiveFinProfilesCount?: number;
}

export const FIN_SETUP_BEHAVIOR_ENDPOINT = '/ember/operator_workflows/fin_setup_behavior';

export default class ConfigureStarterPlan extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare store: Store;
  @service declare contentEditorService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare notificationsService: $TSFixMe;
  @service declare guideLibraryService: GuideLibraryService;
  @service declare aiAgentSetupService: AiAgentSetupService;
  @service declare router: RouterService;
  @service declare finOptInService: FinOptInService;
  @service declare outboundHomeService: any;

  @tracked openSectionId = '';
  @tracked ruleset: $TSFixMe;
  @tracked selectedLocale: string;
  @tracked totalWorkflowsOverriddenIfFinLive = 0;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    let currentRuleset = this.contentEditorService.ruleset;
    this.contentEditorService.unregister(currentRuleset);

    this.selectedLocale = this.appService.app.locale;
    this.updateRuleset(this.args.finSetupRuleset);
    if (this.contentEditorService.ruleset?.id !== this.ruleset.id) {
      this.contentEditorService.register({ ruleset: this.ruleset });
    }
    if (this.args.channelType === 'email') {
      taskFor(this.fetchConversationStartedWorkflows).perform();
    } else {
      taskFor(this.fetchInboundWorkflows).perform();
    }
  }

  get isFinLive() {
    return this.contentEditorService.ruleset.isLive;
  }

  get canSetFinLive() {
    return (
      !this.isFinLive &&
      this.args.hasContentReadyForFin &&
      this.appService.app.canUseExternalAi &&
      (this.args.channelType === 'email' ? this.emailSetupValid : true)
    );
  }

  get isMessengerInstalled() {
    return this.guideLibraryService.isMessengerInstalled;
  }

  get emailSetupValid() {
    let result = this.aiAgentSetupService.validateEmailSetup;
    return result.isValid;
  }

  get behavior(): Profile {
    return this.ruleset.rulesetLinks.firstObject.object;
  }

  get localeOptions() {
    return this.behavior.genericTriageLocalizations.map((localization: $TSFixMe) => ({
      locale_name: localization.name,
      locale_id: `(${localization.locale.charAt(0).toUpperCase()}${localization.locale.slice(1)})`,
      value: localization.locale,
      state: true,
      default: localization.default,
      component: 'operator/generic-triage/locale-option-without-state-badge',
    }));
  }

  get triggerName() {
    if (this.args.channelType === 'email') {
      return this.intl.t('operator.fin.setup.setup-and-go-live.customer-sends-first-message');
    } else {
      return this.intl.t('operator.fin.setup.setup-and-go-live.customer-opens-messenger');
    }
  }

  get triggerIcon(): InterfaceIconName {
    if (this.args.channelType === 'email') {
      return 'manual-message';
    } else {
      return 'wave';
    }
  }
  get bannerContent(): BannerContent {
    if (this.args.channelType === 'email') {
      return {
        heading: 'operator.fin.setup.tabs.setup-and-go-live.banner.non-workflows.email.heading',
        description:
          'operator.fin.setup.tabs.setup-and-go-live.banner.non-workflows.email.description',
        helpLink: {
          text: 'operator.fin.setup.tabs.setup-and-go-live.banner.non-workflows.email.help-link',
          url: 'https://www.intercom.com/help/en/articles/9356221-fin-ai-agent-over-email',
        },
        dismissalKey: 'discovery-banner-fin-over-email-non-workflows',
      };
    }
    return {
      heading: this.args.hasContentReadyForFin
        ? 'operator.fin.setup.tabs.setup-and-go-live.banner.non-workflows.ready-for-live.heading'
        : 'operator.fin.setup.tabs.setup-and-go-live.banner.non-workflows.content-empty.heading',
      description: this.args.hasContentReadyForFin
        ? 'operator.fin.setup.tabs.setup-and-go-live.banner.non-workflows.ready-for-live.description'
        : 'operator.fin.setup.tabs.setup-and-go-live.banner.non-workflows.content-empty.description',
      dismissalKey: this.args.hasContentReadyForFin
        ? `discovery-banner-fin-ready-for-live`
        : `discovery-banner-fin-content-empty`,
    };
  }

  get shouldShowFinOverrideMessage(): boolean {
    return this.appService.app.canUseAiAgentSetupQuickDeploy && !!this.otherAutomationsText;
  }

  get workflowsUrl(): string {
    return this.router.urlFor('apps.app.automation.workflows-overview', {
      queryParams: { hasFin: true, state: 1 },
    });
  }

  get otherAutomationsText(): string | undefined {
    return this.args.channelType === 'email' ? this.emailOverrideMessage : this.chatOverrideMessage;
  }

  get emailOverrideMessage(): string | undefined {
    if (this.totalWorkflowsOverriddenIfFinLive === 0) {
      return;
    }

    return this.intl.t('operator.fin.setup.tabs.setup-and-go-live.priority-over-fin.email', {
      workflowsCount: this.totalWorkflowsOverriddenIfFinLive,
      workflowsUrl: this.workflowsUrl,
      htmlSafe: true,
    });
  }

  get chatOverrideMessage(): string | undefined {
    let hasProfiles =
      this.args.totalLiveFinProfilesCount && this.args.totalLiveFinProfilesCount > 0;

    if (this.totalWorkflowsOverriddenIfFinLive === 0 && !hasProfiles) {
      return;
    }

    let profilesUrl = this.router.urlFor('apps.app.fin-ai-agent.profiles');

    if (this.totalWorkflowsOverriddenIfFinLive > 0 && hasProfiles) {
      return this.intl.t(
        'operator.fin.setup.tabs.setup-and-go-live.priority-over-fin.chat.profiles-and-workflows',
        {
          workflowsCount: this.totalWorkflowsOverriddenIfFinLive,
          workflowsUrl: this.workflowsUrl,
          profilesCount: this.args.totalLiveFinProfilesCount,
          profilesUrl,
          htmlSafe: true,
        },
      );
    } else if (this.totalWorkflowsOverriddenIfFinLive > 0) {
      return this.intl.t(
        'operator.fin.setup.tabs.setup-and-go-live.priority-over-fin.chat.workflows',
        {
          workflowsCount: this.totalWorkflowsOverriddenIfFinLive,
          workflowsUrl: this.workflowsUrl,
          htmlSafe: true,
        },
      );
    } else {
      return this.intl.t(
        'operator.fin.setup.tabs.setup-and-go-live.priority-over-fin.chat.profiles',
        {
          profilesCount: this.args.totalLiveFinProfilesCount,
          profilesUrl,
          htmlSafe: true,
        },
      );
    }
  }

  get contentPageUrl(): string {
    return this.router.urlFor('apps.app.fin-ai-agent.setup', {
      queryParams: {
        activeTab: 'add-content',
      },
    });
  }

  updateRuleset(ruleset: $TSFixMe) {
    let peekRuleset = this.store.peekRecord('matching-system/ruleset', ruleset.id);
    if (!peekRuleset) {
      this.store.pushPayload({ 'matching-system/ruleset': ruleset });
      peekRuleset = this.store.peekRecord('matching-system/ruleset', ruleset.id);
    }
    this.ruleset = peekRuleset;
    this.args.reloadSetupRuleset(this.args.channelType);
  }

  @task({ drop: true })
  *setFinLive() {
    try {
      let ruleset = this.ruleset;
      if (!ruleset) {
        return;
      }

      // to get here, customer has seen the Fin AI Agent overview landing page with all the terms for using Fin
      if (ruleset.isDraft && !this.finOptInService.hasOptedInToFin) {
        yield taskFor(this.finOptInService.postAcceptOptIn).perform();
      }

      yield ruleset.activate();

      this.args.reloadSetupRuleset(this.args.channelType);
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'operator.fin.setup.setup-and-go-live.set-fin-live.notification-messages.success',
        ),
      );
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t(
          'operator.fin.setup.setup-and-go-live.set-fin-live.notification-messages.failure',
        ),
      });
    }
  }

  @task({ drop: true })
  *pauseFin() {
    try {
      let ruleset = this.ruleset;
      if (!ruleset) {
        return;
      }

      yield ruleset.deactivate();

      this.args.reloadSetupRuleset(this.args.channelType);
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'operator.fin.setup.setup-and-go-live.set-fin-live.notification-messages.pause-success',
        ),
      );
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t(
          'operator.fin.setup.setup-and-go-live.set-fin-live.notification-messages.pause-failure',
        ),
      });
    }
  }

  @task({ restartable: true })
  *fetchConversationStartedWorkflows(): TaskGenerator<void> {
    let response = yield this.outboundHomeService.contentSearchWithFilters({
      object_types: [objectTypes.triggerableCustomBot],
      app_id: this.appService.app.id,
      additionalSearchableData: {
        target: CONVERSATION_STARTED_TARGET,
      },
    });

    let conversationStartedWorkflows = response.contentWrappers || [];

    conversationStartedWorkflows.forEach((workflow: any) => {
      let state = workflow.contents.firstObject?.state;
      let hasFinStep = workflow.contents.firstObject.contentData?.has_fin_step;

      if (state === states.live && hasFinStep) {
        this.totalWorkflowsOverriddenIfFinLive += 1;
      }
    });
  }

  @task({ restartable: true })
  *fetchInboundWorkflows(): TaskGenerator<void> {
    let response = yield this.outboundHomeService.contentSearchWithFilters({
      object_types: [objectTypes.inboundCustomBot],
      app_id: this.appService.app.id,
    });

    let inboundWorkflows = response.contentWrappers || [];

    inboundWorkflows.forEach((workflow: any) => {
      let state = workflow.contents.firstObject?.state;
      let hasFinStep = workflow.contents.firstObject.contentData?.has_fin_step;

      if (state === states.live && hasFinStep) {
        this.totalWorkflowsOverriddenIfFinLive += 1;
      }
    });
  }

  partialSave = async (params: FinSetupBehaviorSaveParams) => {
    let baseParams = {
      app_id: this.appService.app.id,
      admin_id: this.appService.app.currentAdmin.id,
      setup_type: this.args.channelType,
    };
    try {
      let ruleset = await put(FIN_SETUP_BEHAVIOR_ENDPOINT, { ...baseParams, ...params });
      this.updateRuleset(ruleset);
      this.notificationsService.notifyConfirmation(
        this.intl.t('operator.fin.setup.setup-and-go-live.notification-messages.success'),
      );
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('operator.fin.setup.setup-and-go-live.notification-messages.failure'),
      });
    }
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::ConfigureStarterPlan': typeof ConfigureStarterPlan;
  }
}
