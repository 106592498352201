/* import __COLOCATED_TEMPLATE__ from './custom-office-hours.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    showCustomOfficeHoursEmptyState: boolean;
    createCustomOfficeHours: () => void;
  };
}

const CustomOfficeHoursTab = templateOnlyComponent<Signature>();
export default CustomOfficeHoursTab;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::OfficeHours::Tabs::CustomOfficeHours': typeof CustomOfficeHoursTab;
    'new-settings/workspace/office-hours/tabs/custom-office-hours': typeof CustomOfficeHoursTab;
  }
}
