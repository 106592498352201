/* import __COLOCATED_TEMPLATE__ from './office-hours-tabbed.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { type TabItem } from '../common/standard-base';
import { inject as service } from '@ember/service';
import type ApplicationInstance from '@ember/application/instance';
import { tracked } from '@glimmer/tracking';

interface Args {
  schedules: any;
  settings: any;
  changeTab: (tab: string) => void;
  tab: string;
}

interface Signature {
  Args: Args;
  Element: never;
}
export default class OfficeHoursTabbed extends Component<Signature> {
  @service declare intercomEventService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare store: any;
  @service declare intl: $TSFixMe;
  @tracked schedules = [];
  @tracked defaultSchedule: any = null;
  @tracked customSchedules = [];
  @tracked hasCustomSchedules = false;

  constructor(owner: ApplicationInstance, args: Args) {
    super(owner, args);
    if (!args.tab) {
      args.changeTab('general');
    }
    this.initializeSchedules();
    this.intl.maybeLoadMessengerTranslations();
  }

  @action
  initializeSchedules() {
    this.schedules = this.schedulesModel.filter((schedule: any) => !schedule.isNew);
    this.defaultSchedule = this.schedules.find((schedule: any) => schedule.isDefault);
    this.customSchedules = this.schedules.filter((schedule: any) => !schedule.isDefault);
    this.hasCustomSchedules = this.customSchedules.length > 0;
  }

  get schedulesModel() {
    return this.args.schedules;
  }

  get tabs(): Array<TabItem> {
    return [
      {
        label: 'new-settings.workspace.office-hours.tabs.general',
        value: 'general',
      },
      {
        label: 'new-settings.workspace.office-hours.tabs.custom-office-hours',
        value: 'custom-office-hours',
      },
    ];
  }

  get showCustomOfficeHoursEmptyState() {
    return !this.customSchedules || this.customSchedules.length === 0;
  }

  @action
  changeTab(newTab: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'navigated',
      object: 'office_hours_tab',
      place: 'settings.office_hours',
      section: newTab,
    });
    this.args.changeTab(newTab);
  }

  @action
  showOfficeHourArticle() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'help_content_link',
      place: 'settings.office_hours',
      section: 'general_office_hours_banner',
    });
    if (this.selectedTab === 'general') {
      window.Intercom('showArticle', 732390); // https://www.intercom.com/help/en/articles/732390-set-your-default-office-hours
    } else if (this.selectedTab === 'custom-office-hours') {
      window.Intercom('showArticle', 3305941); // https://www.intercom.com/help/en/articles/3305941-Define-office-hours-for-each-team-inbox
    }
  }

  @action
  createCustomOfficeHours() {
    // @todo
  }

  @action
  async updateDefaultScheduleIntervals(intervals: any[]) {
    try {
      this.defaultSchedule.timeIntervals = intervals;
      await this.defaultSchedule.save();
      this.initializeSchedules();
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'new-settings.workspace.office-hours.notification.general-office-hours.success',
        ),
      );
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('new-settings.workspace.office-hours.notification.general-office-hours.error'),
      );
      throw error;
    }
  }

  @action
  async saveAvailabilitySetting(params: any) {
    try {
      if (params.reportingExcludeOooHours) {
        this.args.settings.reportingExcludeOooHours = params.reportingExcludeOooHours;
      }
      if (params.customResponseDelayValue) {
        this.args.settings.customResponseDelayValue = params.customResponseDelayValue;
      }
      if (params.customResponseDelayEnabled) {
        this.args.settings.customResponseDelayEnabled = params.customResponseDelayEnabled;
      }
      if (params.customResponseDelay) {
        this.args.settings.customResponseDelay = params.customResponseDelay;
      }

      await this.args.settings.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'new-settings.workspace.office-hours.notification.general-office-hours.success',
        ),
      );
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('new-settings.workspace.office-hours.notification.general-office-hours.error'),
      );
      throw error;
    }
  }

  get selectedTab() {
    return this.args.tab;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::OfficeHoursTabbed': typeof OfficeHoursTabbed;
  }
}
