/* import __COLOCATED_TEMPLATE__ from './personality.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';
import { task } from 'ember-concurrency-decorators';
import type { TaskGenerator } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import type Settings from 'embercom/models/ai-agent/tone-of-voice-settings';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import { defer } from 'rsvp';

export default class Personality extends Component {
  @service declare intl: IntlService & { languageNameFromCode: (code: string) => string };
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service notificationsService: $TSFixMe;
  @service permissionsService: $TSFixMe;

  @tracked operatorIdentity: $TSFixMe = null; // app/models/operator-identity
  @tracked aiAgentToneOfVoiceSettings?: Settings;
  @tracked rerenderIdentityEditor = false;

  avatarType: 'custom' | 'default' | null = null;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    taskFor(this.fetchDetails).perform();
    taskFor(this.loadOperatorIdentity).perform();
  }

  get app() {
    return this.appService.app;
  }

  get tones() {
    return [
      {
        value: 'neutral',
        icon: 'fin' as InterfaceIconName,
        label: this.intl.t('ai-agent.settings.tone-of-voice.tones.neutral.name'),
        description: this.intl.t('ai-agent.settings.tone-of-voice.tones.neutral.description'),
      },
      {
        value: 'plainspoken',
        icon: 'book' as InterfaceIconName,
        label: this.intl.t('ai-agent.settings.tone-of-voice.tones.plainspoken.name'),
        description: this.intl.t('ai-agent.settings.tone-of-voice.tones.plainspoken.description'),
      },
      {
        value: 'professional',
        icon: 'newspaper' as InterfaceIconName,
        label: this.intl.t('ai-agent.settings.tone-of-voice.tones.professional.name'),
        description: this.intl.t('ai-agent.settings.tone-of-voice.tones.professional.description'),
      },
      {
        value: 'friendly',
        icon: 'wave' as InterfaceIconName,
        label: this.intl.t('ai-agent.settings.tone-of-voice.tones.friendly.name'),
        description: this.intl.t('ai-agent.settings.tone-of-voice.tones.friendly.description'),
      },
      {
        value: 'playful',
        icon: 'lwr-happy' as InterfaceIconName,
        label: this.intl.t('ai-agent.settings.tone-of-voice.tones.playful.name'),
        description: this.intl.t('ai-agent.settings.tone-of-voice.tones.playful.description'),
      },
    ];
  }

  get answerLengths() {
    return [
      {
        value: 'succinct',
        icon: 'short-text' as InterfaceIconName,
        label: this.intl.t('ai-agent.settings.answer-length.values.succinct.name'),
        description: this.intl.t('ai-agent.settings.answer-length.values.succinct.description'),
        tooltipDisabled: false,
      },
      {
        value: 'standard',
        icon: 'long-text' as InterfaceIconName,
        label: this.intl.t('ai-agent.settings.answer-length.values.standard.name'),
        description: null,
        tooltipDisabled: true,
      },
      {
        value: 'comprehensive',
        icon: 'extra-long-text' as InterfaceIconName,
        label: this.intl.t('ai-agent.settings.answer-length.values.comprehensive.name'),
        description: this.intl.t(
          'ai-agent.settings.answer-length.values.comprehensive.description',
        ),
        tooltipDisabled: false,
      },
    ];
  }

  get buttonsDisabled() {
    if (this.aiAgentToneOfVoiceSettings) {
      return !this.aiAgentToneOfVoiceSettings.hasDirtyAttributes;
    }
    return true;
  }

  @action
  setTone(tone: string) {
    if (this.aiAgentToneOfVoiceSettings) {
      this.aiAgentToneOfVoiceSettings.aiToneOfVoice = tone;
    }
  }

  @action
  setAnswerLength(length: string) {
    if (this.aiAgentToneOfVoiceSettings) {
      this.aiAgentToneOfVoiceSettings.aiAnswerLength = length;
    }
  }

  @task({ restartable: true })
  *fetchDetails() {
    try {
      let appId = this.appService.app.id;
      this.aiAgentToneOfVoiceSettings = yield this.store.findRecord(
        'ai-agent/tone-of-voice-settings',
        appId,
      );
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('operator.fin.setup.optimize.error-response'),
      );
    }
  }

  @task({ restartable: true })
  *loadOperatorIdentity(): TaskGenerator<void> {
    try {
      this.operatorIdentity = yield this.store.findRecord('operator-identity', '1');
      this.avatarType = this.operatorIdentity.avatarType;
    } catch (e) {
      this.notificationsService.notifyError(this.intl.t('operator.settings.error'));
    }
  }

  @action
  async cancel() {
    this.operatorIdentity.rollbackAttributes();
    this.rerenderIdentityEditor = true; // TODO: remove this when we implement this with a proper ember resource

    if (this.aiAgentToneOfVoiceSettings) {
      this.aiAgentToneOfVoiceSettings.rollbackAttributes();
    }
  }

  @task({ restartable: true })
  *save() {
    try {
      yield this.permissionsService.checkPermission('can_create_and_edit_bots');
    } catch (e) {
      return;
    }
    try {
      yield this.operatorIdentity.save();

      if (this.aiAgentToneOfVoiceSettings) {
        yield this.aiAgentToneOfVoiceSettings.save();
      }

      this.notificationsService.notifyConfirmation(
        this.intl.t('operator.settings.saved-successfully'),
      );
    } catch (error) {
      this.notificationsService.notifyError(this.intl.t('operator.settings.error'));
    }
  }

  _readFileAsDataURL(file: File): Promise<unknown> {
    let deferred = defer();
    let reader = new FileReader();
    reader.onload = (e) => {
      if (e.target && typeof e.target.result === 'string') {
        deferred.resolve(e.target.result);
      }
    };
    reader.readAsDataURL(file);
    return deferred.promise;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::Personality': typeof Personality;
  }
}
